.card {
    border-radius: 25px;
    background-color: #edf8fa;
    border: 2px solid #d64090;
    padding: 0;
    width: 110px;
    height: 150px;
    text-align: center;
    /* line-height: 150px; */
    margin: 10px;
    font-size: 2rem;
}

.card:hover {
    background-color: #d64090;
    color: #fff;
}

.card:hover .adminCommands {
    color: #fff;
}

.cardSmall {
    border-radius: 15px;
    background-color: #edf8fa;
    border: 2px solid #d64090;
    padding: 0;
    width: 55px;
    height: 75px;
    text-align: center;
    line-height: 75px;
    margin: 5px;
    font-size: 1rem;
}

.cardSmall:hover {
    background-color: #d64090;
    color: #fff;
}

.emoji {
    font-size: 4rem;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 150px;
}

.adminCommands {
    font-size: 1rem;
    color: #d64090;
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 25px;
    cursor: pointer;
}