body {
    background-color: #fff;
    color: #0a282f;
    font-family: 'Roboto Mono';
    font-size: 1rem;
}

input {
    font-family: 'Roboto Mono';
    font-size: 1rem;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #edf8fa;
    color: #d64090;
    margin: 10px 0;
}

input:focus {
    outline: 2px solid #d64090;
}

button {
    background-color: #5ed5f3;
    color: #fff;
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-family: 'Roboto Mono';
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
}

.appHeader {
    padding: 25px;
    color: #d64090;
    background-color: #5ed5f3;
}

.appName {
    font-size: 2.5rem;
    font-weight: bold;
}

label {
    font-weight: bold;
    color: #d64090;
    font-size: 1rem;
}