.sessionContainer {

}

.joinContainer {
  padding: 0 25px;
}

.flexContainer {
  display: flex;
}

.leftContainer {
  flex-grow: 2;
  padding: 25px;
}

.rightContainer {
  border-left: 3px solid #5ed5f3;
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.qrCode {
  padding-top: 25px;
  height: 200px;
  width: 200px;
}