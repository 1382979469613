.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 0;
}

.issueContainer {
    background-color: #edf8fa;
    border: 3px solid #5ed5f3;
    padding: 25px;
    font-weight: bold;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
}

.votedMessage {
    padding: 25px 0;
    font-size: 2rem;
}