.issueItem {

}

.issueItem td {
    border-bottom: 1px solid #d64090;
}

.activeIssue::before {
    content: '> ';
}

.activeIssue {
    color: #d64090;
    font-weight: bold;
    font-size: 1.2em;
}